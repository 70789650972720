import { useCallback, useEffect } from "react";
import { Story } from "@lysaab/ui-2";
import { defineMessages, useIntl } from "react-intl";
import { generatePath, useLocation } from "react-router";
import { Route } from "../../../../components/route/Route";
import { Switch } from "../../../../components/route/Switch";
import { getNavLink } from "../../../../hooks/useCountryUrls";
import { useSafeNavigation } from "../../../../hooks/useSafeNavigation";
import { KlarnaIntegrationClient } from "../../../../utils/KlarnaIntegrationClient";
import { PageStripped } from "../../../../pages/PageStripped";
import { OVERVIEW_PAGE_URL } from "../../../overview/OverviewPage";
import { ExternalAccountSelection } from "./externalAccountSelection/ExternalAccountSelection";
import { AccountSelection } from "./accountSelection/AccountSelection";
import { MonthlyAmount } from "./monthlyAmount/MonthlyAmount";
import { Confirm } from "./confirm/Confirm";
import { MonthlyContextProvider, useMonthlyContext } from "./MonthlyContext";
import { BankSelectionWrapper } from "./bankSelection/BankSelectionWrapper";
import { Intro } from "./Intro/Intro";
import { LysaAccountSelection } from "./lysaAccountSelection/LysaAccountSelection";
import { AccountLoadingWrapper } from "./accountLoading/AccountLoadingWrapper";
import { Done } from "./done/Done";
import { ManualAccountSelection } from "./manualAccountSelection/ManualAccountSelection";
import { useStoryValues } from "../../../../hooks/useStoryValues";
import { Variant } from "@lysaab/shared";
import { experimentKeys } from "../../../../experimentConfig";
import { TinkBankSelectionWrapper } from "./bankSelection/TinkSelectionWrapper";

export const MONTHLY_DEPOSITS_URL = "/monthly/create";

export const BASE_ROUTES = {
  INTRO: `${MONTHLY_DEPOSITS_URL}`,
  LYSA_ACCOUNT: `${MONTHLY_DEPOSITS_URL}/lysa-account`,
  EXTERNAL_ACCOUNT: `${MONTHLY_DEPOSITS_URL}/external-account/`,
  BANK_SELECTION: `${MONTHLY_DEPOSITS_URL}/bank-selection`,
  ACCOUNT_LOADING: `${MONTHLY_DEPOSITS_URL}/account-loading`,
  ACCOUNT_SELECTION: `${MONTHLY_DEPOSITS_URL}/account-selection`,
  MANUAL_ACCOUNT_SELECTION: `${MONTHLY_DEPOSITS_URL}/manual-account-selection`,
  AMOUNT: `${MONTHLY_DEPOSITS_URL}/amount`,
  CONFIRM: `${MONTHLY_DEPOSITS_URL}/confirm`,
  DONE: `${MONTHLY_DEPOSITS_URL}/done`,
};

const messages = defineMessages({
  header: {
    id: "deposits.monthly.story.header",
  },
  ariaProgressLabel: {
    id: "deposits.monthly.story.ariaProgressLabel",
  },
});

function InternalMonthlyStory({ isKlarnaAvailable = true }) {
  const monthlyContext = useMonthlyContext();
  const safeNavigation = useSafeNavigation();
  const location = useLocation();
  const intl = useIntl();
  const [currentIndex, ROUTES, storyProgress, storyLength] =
    useStoryValues(BASE_ROUTES);

  useEffect(() => {
    KlarnaIntegrationClient.preLoad();
  }, []);

  const onBack = (currentIndex: number) => {
    if (currentIndex === 0) {
      return;
    } else if (
      location.pathname === ROUTES.ACCOUNT_SELECTION ||
      location.pathname === ROUTES.MANUAL_ACCOUNT_SELECTION
    ) {
      return safeNavigation(ROUTES.EXTERNAL_ACCOUNT);
    } else if (location.pathname === ROUTES.AMOUNT) {
      if (monthlyContext.state.klarnaAccounts) {
        safeNavigation(ROUTES.ACCOUNT_SELECTION);
      } else {
        safeNavigation(ROUTES.EXTERNAL_ACCOUNT);
      }
      return;
    } else {
      return safeNavigation(
        generatePath(Object.values(ROUTES)[currentIndex - 1])
      );
    }
  };

  const accountLoadingNext = useCallback(() => {
    safeNavigation(ROUTES.ACCOUNT_SELECTION);
  }, [ROUTES.ACCOUNT_SELECTION, safeNavigation]);

  return (
    <PageStripped>
      <Story
        ariaLabelProgress={() =>
          intl.formatMessage(messages.ariaProgressLabel, {
            current: currentIndex,
            total: storyLength,
          })
        }
        header={intl.formatMessage(messages.header)}
        progress={storyProgress}
        showBack={
          location.pathname !== ROUTES.INTRO &&
          location.pathname !== ROUTES.DONE
        }
        showClose={true}
        transitionKey={currentIndex.toString()}
        onExit={() => safeNavigation(getNavLink(OVERVIEW_PAGE_URL))}
        onBack={() => onBack(currentIndex)}
      >
        <Switch
          location={location}
          {...{
            order: currentIndex,
          }}
        >
          <Route
            path={ROUTES.INTRO}
            exact
            render={() => (
              <Intro next={() => safeNavigation(ROUTES.LYSA_ACCOUNT)} />
            )}
          />
          <Route
            path={ROUTES.LYSA_ACCOUNT}
            exact
            render={() => (
              <LysaAccountSelection
                next={() => safeNavigation(ROUTES.EXTERNAL_ACCOUNT)}
              />
            )}
          />
          <Route
            path={ROUTES.EXTERNAL_ACCOUNT}
            exact
            render={() => (
              <ExternalAccountSelection
                next={() => safeNavigation(generatePath(ROUTES.AMOUNT))}
                isKlarnaAvailable={isKlarnaAvailable}
              />
            )}
          />
          <Route
            path={ROUTES.BANK_SELECTION}
            exact
            render={() => (
              <>
                <Variant propertyKey={experimentKeys.TINK_ENABLED} value="true">
                  <TinkBankSelectionWrapper
                    next={() => safeNavigation(ROUTES.ACCOUNT_LOADING)}
                  />
                </Variant>
                <Variant
                  propertyKey={experimentKeys.TINK_ENABLED}
                  value="false"
                >
                  <BankSelectionWrapper
                    next={() =>
                      safeNavigation(generatePath(ROUTES.ACCOUNT_LOADING))
                    }
                  />
                </Variant>
              </>
            )}
          />
          <Route
            path={ROUTES.ACCOUNT_LOADING}
            exact
            render={() => <AccountLoadingWrapper next={accountLoadingNext} />}
          />
          <Route
            path={ROUTES.ACCOUNT_SELECTION}
            exact
            render={() => (
              <AccountSelection next={() => safeNavigation(ROUTES.AMOUNT)} />
            )}
          />
          <Route
            path={ROUTES.MANUAL_ACCOUNT_SELECTION}
            exact
            render={() => (
              <ManualAccountSelection
                next={() => safeNavigation(ROUTES.AMOUNT)}
              />
            )}
          />
          <Route
            path={ROUTES.AMOUNT}
            exact
            render={() => (
              <MonthlyAmount next={() => safeNavigation(ROUTES.CONFIRM)} />
            )}
          />
          <Route
            path={ROUTES.CONFIRM}
            exact
            render={() => <Confirm next={() => safeNavigation(ROUTES.DONE)} />}
          />
          <Route path={ROUTES.DONE} exact render={() => <Done />} />
        </Switch>
      </Story>
    </PageStripped>
  );
}

export const MonthlyStory = ({ isKlarnaAvailable = true }) => (
  <MonthlyContextProvider>
    <InternalMonthlyStory isKlarnaAvailable={isKlarnaAvailable} />
  </MonthlyContextProvider>
);
