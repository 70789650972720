import { useEffect, useState } from "react";
import * as React from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { SNACKBAR_TYPES, Snackbar, Typography } from "@lysaab/ui-2";
import {
  ActivePensionMoveInResponse,
  waitingForInsuredStatuses,
  dataLifePensionMove,
} from "../../../data/dataLifePensionMove";
import { CardButton } from "./CardButton";
import { Modal } from "../../../components/modal/Modal";
import { MoveCard } from "../../../countries/sweden/pages/transferPensions/components/moveCard/MoveCard";
import { MailingInstructions } from "../../../countries/sweden/pages/transferPensions/components/mailingInstructions/MailingInstructions";
import { MoveSteps } from "../../../countries/sweden/pages/transferPensions/components/moveSteps/MoveSteps";
import { AccountType } from "../../../data/dataAccounts";

import "./PendingPensions.scss";

interface MoveDetails {
  move: ActivePensionMoveInResponse | undefined;
  showModal: boolean;
}

export const PendingPensions: React.VFC = () => {
  const intl = useIntl();
  const [moves, setMoves] = useState<ActivePensionMoveInResponse[]>([]);
  const [moveDetails, setMoveDetails] = useState<MoveDetails>({
    showModal: false,
    move: undefined,
  });

  useEffect(() => {
    dataLifePensionMove.getActiveMoves().then((response) => {
      setMoves(response);
    });
  });

  if (moves.length === 0) {
    return null;
  }

  return (
    <div className="overview-pending">
      <Typography type="h2">
        <FormattedMessage id="sweden.pending-pensions.header" />
      </Typography>

      <div className="list">
        {moves.map((move) => {
          const text =
            move.type === AccountType.LYSA_PPF
              ? intl.formatMessage({
                  id: "sweden.pending-pensions.private-insurance",
                })
              : intl.formatMessage(
                  {
                    id: "sweden.pending-pensions.pension-from",
                  },
                  {
                    employer:
                      move.employer ??
                      intl.formatMessage({
                        id: "sweden.pending-pensions.pension-from.unknown",
                      }),
                  }
                );

          return (
            <CardButton
              key={move.id}
              text={text}
              onClick={() => {
                setMoveDetails({ move, showModal: true });
              }}
            />
          );
        })}
      </div>

      <Modal
        header={intl.formatMessage({
          id: "sweden.pending-pensions.modal.header",
        })}
        showModal={moveDetails.showModal}
        onClose={() => {
          setMoveDetails({ ...moveDetails, showModal: false });
        }}
        closeOnOverlayClick
      >
        {moveDetails.move && (
          <div>
            <div className="PendingPensions__moves">
              <MoveCard
                insuranceCompany={moveDetails.move.institute}
                insuranceNumber={moveDetails.move.insuranceNumber}
                insuranceHolderName={moveDetails.move.employer}
                type={moveDetails.move.type}
              />
            </div>

            {waitingForInsuredStatuses.includes(moveDetails.move.state) && (
              <MailingInstructions
                caseId={moveDetails.move.caseId}
                moveId={moveDetails.move.id}
              />
            )}

            <div className="PendingPensions__steps-header">
              <Typography type="label">
                <FormattedMessage id="sweden.pending-pensions.modal.steps.header" />
              </Typography>
            </div>

            <Snackbar type={SNACKBAR_TYPES.INFO} icon textAlign="left">
              <FormattedMessage id="sweden.pending-pensions.modal.steps.notification" />
            </Snackbar>

            <MoveSteps
              pensionMoveStatuses={[moveDetails.move.state]}
              accountTypes={[moveDetails.move.type]}
            />
          </div>
        )}
      </Modal>
    </div>
  );
};
