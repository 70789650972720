import { FunctionComponent, useRef, useState } from "react";
import { Move, useTransfer } from "../../TransferContext";
import {
  Button,
  Form,
  InvalidCompanyValidator,
  LysaFormRef,
  MinLengthValidator,
  OrgValidator,
  RadioGroupCard,
  RequiredValidator,
  Select,
  Snackbar,
  SNACKBAR_TYPES,
  TextInput,
} from "@lysaab/ui-2";
import { useIntl } from "react-intl";
import { AccountType } from "../../../../../../data/dataAccounts";
import { TranslatedText } from "../../../../../../components/TranslatedText";
import { instituteAlternatives } from "../../utils/institutePrettyNames";

interface Props {
  closeModal: () => void;
  incoming?: Move;
  type?: "add" | "edit";
}

export const ManualModalContent: FunctionComponent<Props> = ({
  closeModal,
  incoming,
  type,
}) => {
  const formRef = useRef<LysaFormRef>();
  const intl = useIntl();
  const [transfer, setTransfer] = useTransfer();
  const [move, setMove] = useState<Move>({
    type: incoming?.type || undefined,
    institute: incoming?.institute || undefined,
    insuranceNumber: incoming?.insuranceNumber || undefined,
    employer: incoming?.employer || undefined,
    employerTin: incoming?.employerTin || undefined,
    checked: incoming?.checked || true,
    id: incoming?.id || crypto.randomUUID(),
  });

  return (
    <Form
      lysaFormRef={formRef}
      onSubmit={(event: React.SyntheticEvent<HTMLFormElement, SubmitEvent>) => {
        event.preventDefault();
        if (!formRef.current?.isValid) {
          return;
        }
        if (type === "add") {
          setTransfer({
            moves: [...transfer.moves, move],
          });
        }
        if (type === "edit") {
          const nextMoves = transfer.moves.filter(
            (move) => move.id !== incoming?.id
          );
          setTransfer({
            moves: [...nextMoves, move],
          });
        }
        closeModal();
        event.stopPropagation();
      }}
    >
      <RadioGroupCard
        alternatives={[
          {
            header: intl.formatMessage({
              id: "sweden.transfer-pension.pension-type.tjp.header",
            }),
            description: intl.formatMessage({
              id: "sweden.transfer-pension.pension-type.tjp.description",
            }),
            value: AccountType.LYSA_TJP,
          },
          {
            header: intl.formatMessage({
              id: "sweden.transfer-pension.pension-type.ppf.header",
            }),
            description: intl.formatMessage({
              id: "sweden.transfer-pension.pension-type.ppf.description",
            }),
            value: AccountType.LYSA_PPF,
          },
        ]}
        legend={
          <label className="radio-group-card-label">
            <TranslatedText id="sweden.transfer-pension.pension-type.label" />
          </label>
        }
        onChange={(newType) => {
          setMove({ ...move, type: newType });
        }}
        validators={[
          new RequiredValidator(
            intl.formatMessage({
              id: "sweden.transfer-pension.pension-type.required",
            })
          ),
        ]}
        value={move.type}
      />
      <Select
        label={intl.formatMessage({
          id: "sweden.transfer-pension.insurance-info.institute-select.label",
        })}
        placeholder={intl.formatMessage({
          id: "sweden.transfer-pension.insurance-info.institute-select.placeholder",
        })}
        validators={[
          new RequiredValidator(
            intl.formatMessage({
              id: "sweden.transfer-pension.insurance-info.institute-select.error",
            })
          ),
        ]}
        alternatives={instituteAlternatives}
        value={instituteAlternatives.find(
          (alternative) => alternative.value === move.institute
        )}
        onChange={(newInstitute) => {
          setMove({ ...move, institute: newInstitute.value });
        }}
      />
      <TextInput
        label={intl.formatMessage({
          id: "sweden.transfer-pension.insurance-info.insurance-number-input.label",
        })}
        placeholder={intl.formatMessage({
          id: "sweden.transfer-pension.insurance-info.insurance-number-input.placeholder",
        })}
        validators={[
          new RequiredValidator(
            intl.formatMessage({
              id: "sweden.transfer-pension.insurance-info.insurance-number-input.required.error",
            })
          ),
          new MinLengthValidator(
            5,
            intl.formatMessage({
              id: "sweden.transfer-pension.insurance-info.insurance-number-input.length.error",
            })
          ),
        ]}
        value={move.insuranceNumber ?? ""}
        onChange={(newInsuranceNumber) => {
          setMove({ ...move, insuranceNumber: newInsuranceNumber });
        }}
        showSuccessState
      />
      {(move.type === undefined || move.type === AccountType.LYSA_TJP) && (
        <TextInput
          label={intl.formatMessage({
            id: "sweden.transfer-pension.employer-info.org-number-input.label",
          })}
          placeholder={intl.formatMessage({
            id: "sweden.transfer-pension.employer-info.org-number-input.placeholder",
          })}
          validators={[
            new RequiredValidator(
              intl.formatMessage({
                id: "sweden.transfer-pension.employer-info.org-number-input.required",
              })
            ),
            new OrgValidator(
              intl.formatMessage({
                id: "sweden.transfer-pension.employer-info.org-number-input.error",
              })
            ),
            new InvalidCompanyValidator(
              intl.formatMessage({
                id: "sweden.transfer-pension.employer-info.org-number-input.corporation.error",
              })
            ),
          ]}
          value={move.employerTin ?? ""}
          onChange={(newEmployerTin) => {
            setMove({ ...move, employerTin: newEmployerTin });
          }}
        />
      )}
      <Snackbar type={SNACKBAR_TYPES.INFO} icon>
        <div style={{ textAlign: "left" }}>
          <TranslatedText
            id="sweden.transfer-pension.insurance-info.snackbar"
            values={{
              link: (text) => {
                return (
                  <a
                    className="snackbar-link"
                    target="_blank"
                    href="https://www.minpension.se/"
                    rel="noreferrer"
                  >
                    <strong>{text}</strong>
                  </a>
                );
              },
            }}
          />
        </div>
      </Snackbar>
      <Button
        block
        type="submit"
        variant="primary"
        label={intl.formatMessage({
          id: "sweden.transfer-pension.insurance-info.add",
          defaultMessage: "Fortsätt",
        })}
      />
    </Form>
  );
};
