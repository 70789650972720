import { FunctionComponent } from "react";

import { useIntl } from "react-intl";
import { MAX_AGE } from "../TransferContext";
import { getUserAge } from "../utils/userAge";
import { Ingress } from "../components/ingress/Ingress";
import { Button, NewIcon, Typography } from "@lysaab/ui-2";
import { useUser } from "../../../../../context/UserContext";
import { TranslatedText } from "../../../../../components/TranslatedText";

import "./Intro.scss";

interface Props {
  next: () => void;
  exit: () => void;
}

export const Intro: FunctionComponent<Props> = ({ next, exit }) => {
  const intl = useIntl();
  const user = useUser();
  const age = getUserAge(user.tin);

  if (age && age > MAX_AGE) {
    return (
      <article className="transfer-pension-intro">
        <header>
          <NewIcon.AlertOutline
            size={96}
            primaryColor="var(--lysa-icon-secondary)"
          />
          <Typography type="display-small">
            <TranslatedText id={"sweden.transfer-pension.intro.block.header"} />
          </Typography>
          <Ingress>
            <TranslatedText
              id={"sweden.transfer-pension.intro.block.reason"}
              values={{
                max: (text) => {
                  return <span>{MAX_AGE}</span>;
                },
              }}
            />
          </Ingress>
        </header>
        <Button
          style={{ marginTop: "6rem" }}
          block
          variant="primary"
          type="button"
          label={intl.formatMessage({
            id: "sweden.transfer-pension.intro.exit.button",
          })}
          onClick={exit}
        />
      </article>
    );
  }

  return (
    <article className="transfer-pension-intro">
      <header>
        <NewIcon.Move size={96} primaryColor="var(--lysa-icon-secondary)" />
        <Typography type="display-small">
          <TranslatedText
            id={"sweden.transfer-pension.intro.header"}
            values={{
              first: (text) => {
                return (
                  <span>
                    {text}
                    <br />
                  </span>
                );
              },
              second: (text) => {
                return <span>{text}</span>;
              },
            }}
          />
        </Typography>
        <Ingress>
          <TranslatedText id={"sweden.transfer-pension.intro.ingress"} />
        </Ingress>
        <section className="time-estimate">
          <NewIcon.Clock size={16} primaryColor="var(--lysa-text-secondary)" />
          <TranslatedText id={"sweden.transfer-pension.intro.time-estimate"} />
        </section>
        <hr className="divider" />
      </header>
      <ul>
        <li className="list-item">
          <span className="icon-circle">
            <NewIcon.Rocket />
          </span>
          <TranslatedText id={"sweden.transfer-pension.intro.list-item-1"} />
        </li>
        <li className="list-item">
          <span className="icon-circle">
            <NewIcon.Calendar />
          </span>
          <TranslatedText id={"sweden.transfer-pension.intro.list-item-2"} />
        </li>
        <li className="list-item">
          <span className="icon-circle">
            <NewIcon.Eye />
          </span>
          <TranslatedText id={"sweden.transfer-pension.intro.list-item-3"} />
        </li>
      </ul>
      <Button
        className="next"
        block
        variant="primary"
        onClick={next}
        label={intl.formatMessage({
          id: "sweden.transfer-pension.intro.button",
        })}
      />
    </article>
  );
};
