import { Institute } from "../../../../../data/dataLifePensionMove";

export const institutePrettyNames: Record<Institute, string> = {
  [Institute.AVANZA]: "Avanza",
  [Institute.FOLKSAM]: "Folksam",
  [Institute.FUTUR]: "Futur",
  [Institute.HANDELSBANKEN]: "Handelsbanken",
  [Institute.LANSFORSAKRINGAR]: "Länsförsäkringar",
  [Institute.NORDEA]: "Nordea",
  [Institute.NORDNET]: "Nordnet",
  [Institute.MOVESTIC]: "Movestic",
  [Institute.SEB]: "SEB",
  [Institute.SKANDIA]: "Skandia",
  [Institute.SPP]: "SPP",
  [Institute.SWEDBANK]: "Swedbank",
};

export const instituteAlternatives = Object.values(Institute)
  .filter((value) => value !== Institute.SWEDBANK) // Swedbank was only part of test phase
  .map((value) => ({
    text: institutePrettyNames[value],
    value,
  }));
