import { FunctionComponent, useEffect, useMemo, useRef, useState } from "react";

import { useIntl } from "react-intl";
import { useTransfer } from "../TransferContext";
import {
  Button,
  Form,
  InputErrorList,
  LysaFormRef,
  RequiredValidator,
  Typography,
  useForm,
  useValidation,
} from "@lysaab/ui-2";
import { TranslatedText } from "../../../../../components/TranslatedText";
import {
  GetPensionDataResponse,
  InsurelyPrivatePensions,
  ScrapedPensionData,
  dataLifePensionMove,
} from "../../../../../data/dataLifePensionMove";
import { AccountType } from "../../../../../data/dataAccounts";

import "./InsuranceInformationSummary.scss";
import { MoveCard } from "../components/moveCard/MoveCard";

interface Props {
  next: () => void;
  goBack: () => void;
}

export const InsuranceInformationSummary: FunctionComponent<Props> = ({
  next,
  goBack,
}) => {
  const formRef = useRef<LysaFormRef>();
  const intl = useIntl();

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [transfer, setTransfer] = useTransfer();
  const [scrapedPensionData, setScrapedPensionData] =
    useState<GetPensionDataResponse>();
  const [checked, setChecked] = useState<{ [id: string]: boolean }>({});

  useEffect(() => {
    dataLifePensionMove.getPensionData().then((pensionData) => {
      const newChecked: { [id: string]: boolean } = {};
      pensionData?.movability?.["MOVABLE"]?.forEach((movableMove) => {
        newChecked[movableMove.id] = true;
      });
      setChecked(newChecked);
      setScrapedPensionData(pensionData);
    });
  }, []);

  console.log("transfer: ", transfer);

  const movable = scrapedPensionData?.movability?.["MOVABLE"];
  const unknown = scrapedPensionData?.movability?.["UNKNOWN"];
  const notMovable = scrapedPensionData?.movability?.["NOT_MOVABLE"];
  const noPensionFound = scrapedPensionData?.noPensionsFound
    ? Object.keys(scrapedPensionData?.noPensionsFound)
    : [];

  const hasPotentiallyMovablePensions = movable?.length || unknown?.length;

  return (
    <article className="transfer-pension-insurance-information-summary">
      <Form
        lysaFormRef={formRef}
        onSubmit={(_) => {
          const movableMoves =
            movable
              ?.filter((movableMove) => checked[movableMove.id])
              ?.map((movableMove) => {
                if (InsurelyPrivatePensions.includes(movableMove.pensionType)) {
                  return {
                    type: AccountType.LYSA_PPF,
                    insuranceNumber: movableMove?.insuranceNumber,
                    institute: movableMove.insuranceCompany,
                    id: movableMove.id,
                    currentWorth: movableMove?.currentWorth,
                    pensionId: movableMove?.id,
                  };
                } else {
                  return {
                    type: AccountType.LYSA_TJP,
                    insuranceNumber: movableMove.insuranceNumber,
                    institute: movableMove.insuranceCompany,
                    employerTin: movableMove?.insuranceHolderTin,
                    employer: movableMove?.insuranceHolderName,
                    id: movableMove.id,
                    currentWorth: movableMove?.currentWorth,
                    pensionId: movableMove?.id,
                  };
                }
              }) || [];

          const unknownMoves =
            unknown
              ?.filter((unknownMove) => checked[unknownMove.id])
              ?.map((unknownMove) => {
                if (InsurelyPrivatePensions.includes(unknownMove.pensionType)) {
                  return {
                    type: AccountType.LYSA_PPF,
                    insuranceNumber: unknownMove?.insuranceNumber,
                    institute: unknownMove.insuranceCompany,
                    id: unknownMove.id,
                    currentWorth: unknownMove?.currentWorth,
                    pensionId: unknownMove?.id,
                  };
                } else {
                  return {
                    type: AccountType.LYSA_TJP,
                    insuranceNumber: unknownMove.insuranceNumber,
                    institute: unknownMove.insuranceCompany,
                    employerTin: unknownMove?.insuranceHolderTin,
                    employer: unknownMove?.insuranceHolderName,
                    id: unknownMove.id,
                    currentWorth: unknownMove?.currentWorth,
                    pensionId: unknownMove?.id,
                  };
                }
              }) || [];

          const nextMoves = [
            ...movableMoves,
            ...unknownMoves,
            ...transfer.moves,
          ];
          if (formRef.current?.isValid && nextMoves.length > 0) {
            setTransfer({ moves: nextMoves });
            next();
          }
        }}
      >
        <Typography type="h2" className="header">
          <TranslatedText id="sweden.transfer-pension.insurance-information.summary.header" />
        </Typography>
        <Typography type="body">
          <TranslatedText id="sweden.transfer-pension.insurance-information.summary.ingress" />
        </Typography>
        <ActionableInsurances
          movable={movable}
          unknown={unknown}
          checked={checked}
          setChecked={setChecked}
        />
        {notMovable && (
          <section className="move-cards">
            <Typography type="h3" className="move-cards-header">
              <TranslatedText id="sweden.transfer-pension.insurance-information.summary.not-movable.header" />
            </Typography>
            {notMovable.map((notMovableMove) => {
              return (
                <MoveCard
                  key={notMovableMove.id}
                  insuranceCompany={notMovableMove.insuranceCompany}
                  insuranceNumber={notMovableMove?.insuranceNumber}
                  insuranceHolderName={notMovableMove?.insuranceHolderName}
                  currentWorth={notMovableMove?.currentWorth}
                  information={notMovableMove?.information}
                />
              );
            })}
          </section>
        )}
        {noPensionFound.length > 0 && (
          <section className="move-cards">
            <Typography type="h3" className="move-cards-header">
              <TranslatedText id="sweden.transfer-pension.insurance-information.summary.no-pension-found.header" />
            </Typography>
            {noPensionFound.map((insuranceCompany) => {
              return (
                <MoveCard
                  key={insuranceCompany}
                  insuranceCompany={insuranceCompany}
                />
              );
            })}
          </section>
        )}
        <section className="next">
          {hasPotentiallyMovablePensions ? (
            <Button
              block
              variant="primary"
              type="submit"
              label={intl.formatMessage({
                id: "sweden.transfer-pension.insurance-information.summary.next-button",
              })}
            />
          ) : (
            <>
              <Button
                block
                variant="primary"
                type="button"
                label={intl.formatMessage({
                  id: "sweden.transfer-pension.insurance-information.summary.back-button",
                })}
                onClick={() => {
                  goBack();
                }}
              />
            </>
          )}
        </section>
      </Form>
    </article>
  );
};

type ActionableInsurancesProps = {
  movable: ScrapedPensionData[] | undefined;
  unknown: ScrapedPensionData[] | undefined;
  checked: { [id: string]: boolean };
  setChecked: React.Dispatch<React.SetStateAction<{ [id: string]: boolean }>>;
};

function ActionableInsurances({
  movable,
  unknown,
  checked,
  setChecked,
}: ActionableInsurancesProps) {
  const intl = useIntl();
  const values = useMemo(
    () => Object.values(checked).filter(Boolean),
    [checked]
  );

  const [inputName] = useState(
    "insurance_summary_group_" + Math.random().toString(36).substr(2, 9)
  );
  const containerRef = useRef<HTMLFieldSetElement>(null);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [validity, errorMessages, validityClasses, resetValidation] =
    useValidation<boolean[]>(
      values,
      [
        new RequiredValidator(
          intl.formatMessage({
            id: "sweden.transfer-pension.insurance-information.summary.noCompaniesSelected.warning",
          })
        ),
      ],
      false
    );

  useForm(
    inputName,
    validity,
    values,
    resetValidation,
    containerRef,
    errorMessages
  );

  return (
    <fieldset ref={containerRef} className="movable-insurance-fieldset">
      {movable && (
        <section className="move-cards">
          <Typography type="h3" className="move-cards-header">
            <TranslatedText id="sweden.transfer-pension.insurance-information.summary.movable.header" />
          </Typography>
          {movable.map((movableMove) => {
            return (
              <MoveCard
                key={movableMove.id}
                insuranceCompany={movableMove?.insuranceCompany}
                insuranceNumber={movableMove?.insuranceNumber}
                insuranceHolderName={movableMove?.insuranceHolderName}
                checked={checked[movableMove.id]}
                currentWorth={movableMove?.currentWorth}
                information={movableMove?.information}
                type={movableMove?.pensionType}
                handleChange={() => {
                  setChecked((oldChecked) => {
                    return {
                      ...oldChecked,
                      [movableMove.id]: !oldChecked[movableMove.id],
                    };
                  });
                }}
              />
            );
          })}
        </section>
      )}
      {unknown && (
        <section className="move-cards">
          <Typography type="h3" className="move-cards-header">
            <TranslatedText id="sweden.transfer-pension.insurance-information.summary.unknown.header" />
          </Typography>
          {unknown.map((unknownMove) => {
            return (
              <MoveCard
                key={unknownMove.id}
                insuranceCompany={unknownMove.insuranceCompany}
                insuranceNumber={unknownMove?.insuranceNumber}
                insuranceHolderName={unknownMove?.insuranceHolderName}
                checked={checked[unknownMove.id]}
                currentWorth={unknownMove?.currentWorth}
                information={unknownMove?.information}
                type={unknownMove?.pensionType}
                handleChange={() => {
                  setChecked((oldChecked) => {
                    return {
                      ...oldChecked,
                      [unknownMove.id]: !oldChecked[unknownMove.id],
                    };
                  });
                }}
              />
            );
          })}
        </section>
      )}
      {errorMessages.length > 0 && (
        <div className="error-container">
          <InputErrorList errorMessages={errorMessages} />
        </div>
      )}
    </fieldset>
  );
}
