import { API } from "@lysaab/ui-2";

export type TinkBankId = string & { readonly isTinkBankId: true };
export type accountVerificationReportId = string & {
  readonly isAccountVerificationReportId: true;
};

export interface TinkAvailableBank {
  id: TinkBankId;
  name: string;
  iconUrl: string;
}

export interface TinkSession {
  sessionId: string;
  clientId: string;
  providerName: string;
}

export interface TinkAccount {
  iban: string;
  accountNumber: string;
  name: string;
}

export const dataTink = {
  getAllBanks: () => {
    return API.get<TinkAvailableBank[]>("/tink/bank");
  },
  createSession: (bankId: TinkBankId) => {
    return API.post<TinkSession>("/tink/session", { bankId });
  },
  getAccounts: (accountVerificationReportId: accountVerificationReportId) => {
    return API.get<{ accounts: TinkAccount[] }>(
      "/tink/account?accountVerificationReportId=" + accountVerificationReportId
    );
  },
};
